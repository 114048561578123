import $ from 'jquery';
import changeStyle from './components/top/shapes';
import initializeWave from './components/top/canvaswave';
import initHeader from './components/header';

initHeader(false);
changeStyle();
initializeWave('is-top');

// splash;
const $splash = $('#splash');
if (!sessionStorage.getItem('welcome')) {
  sessionStorage.setItem('welcome', true);
  const $splashVideo = $('#splash-video');
  const splashRemove = () => {
    $splash.addClass('is-remove');
  };
  const videoRemove = () => {
    $splashVideo.addClass('is-remove');
  };
  const videoElementsRemove = () => {
    $splash.remove();
  };
  setTimeout(videoRemove, 2800);
  setTimeout(splashRemove, 3300);
  setTimeout(videoElementsRemove, 5000);
} else {
  $splash.css('display', 'none');
}
$(window).on('load', () => {
  const $bg01 = $('.bg-container__colors__item--01');
  $bg01.css('transition-duration', '1.0s');
});
